
import Vue, { PropType } from "vue";

import { Building, Cleaning } from "@/interfaces";

import { mdiDownload } from "@mdi/js";

import dayjs from "dayjs";

export default Vue.extend({
  components: {},
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  computed: {
    icon() {
      return { mdiDownload };
    },
  },
});
